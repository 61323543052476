@import '~antd/lib/style/themes/default.less';
@bodyWidth: 1400px;
@mobileWidth: 800px;

@headerHeight: 64px;
@headerLineHeight: 64px;
@footerHeight: 128px;

@secondaryBGColor: #fafafa;
@bgColor: #fff;

@borderColor: @normal-color;

@primaryColor: @primary-color;

@purple: #5c28a9;
@blue: #48adb8;

@fuchsia: #d741a7;

@fontColor: #000;
@textColor: #000;
@lightTextColor: #fff;
@grayText: #999;
@titleGrayText: #999;

@boxColor: #fff;

@green: #07a88b;
@red: #d34a64;
@tableTitle: #fafafa;

.containerDefault {
  margin: 0 auto;
  max-width: @bodyWidth;
}
.antd-loading-lg {
  .ant-spin-dot-spin {
    font-size: 40px;
    .ant-spin-dot-item {
      width: 20px;
      height: 20px;
    }
  }
}
