@import '../../../../common/variables.less';
.info-table {
  a {
    text-decoration: none;
    color: inherit;
  }
}
.info-table-date {
  width: 140px;
}
@media (max-width: @mobileWidth) {
  .info-table-date {
    width: 100px;
  }
}

@primary-color: #4E81F8;