@import '~antd/dist/antd.less';
@import './variables.less';
@maxWidth: @bodyWidth;
button {
  cursor: pointer;
  outline: 0;
}
body {
  color: @fontColor;
  background: @bgColor;
  margin: 0;
  font-family: 'Overpass Mono', sans-serif;
  font-size: 14px;
  // font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: underline;
}
h1,
h2,
h3,
p,
a {
  color: @textColor;
}
.layout {
  background-color: @secondaryBGColor;
}
.content {
  background: @boxColor;
  width: 100%;
  min-height: calc(100vh - 48px - @headerHeight - @footerHeight);
  margin: 24px auto;
  padding: 20px 40px;
}
.header {
  background: @boxColor;
  padding: 0 40px;
  .header-body {
    width: 100%;
  }
}
.footer {
  padding: 0;
  background: @boxColor;
  height: @footerHeight;
  padding: 0 40px;
  .footer-body {
    height: 100%;
    width: 100%;
  }
}

.overview-charts {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: @primaryColor;
}
.overview-charts-charts-box {
  display: flex;
  flex: 1;
  background: @boxColor;
  flex-direction: column;
  padding: 10px;
}
.overview-radio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lending-radio {
  padding: 0 10px;
}
.ant-table-content {
  margin: 10px 0;
}
.ant-table-wrapper {
  .ant-table {
    font-size: inherit;
    thead {
      font-weight: 500;
    }
    .ant-table-row {
      cursor: pointer;
    }
  }
  .ant-spin-dot-spin {
    font-size: 40px;
    .ant-spin-dot-item {
      width: 20px;
      height: 20px;
    }
  }
}

.dropdown-menu {
  border: 1px solid @borderColor;
  height: 400px;
  overflow: auto;
  font-weight: 400;
  .ant-menu-item {
    line-height: 25px;
    height: 25px;
    font-size: 12px;
  }
}
//Override antd table style
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td,
.ant-table-column-sorters {
  padding: 16px 3px;
}
.ant-table-tbody td.ant-table-column-sort {
  background: @bgColor;
}
.ant-table-thead th.ant-table-column-sort {
  background: @secondaryBGColor;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: @secondaryBGColor;
}
@media (max-width: 1100px) {
  body {
    font-size: 13px;
  }
}
@media (min-width: @maxWidth) {
  .content {
    .containerDefault();
    min-width: @bodyWidth;
    margin: 24px auto;
  }
  .header {
    .header-body {
      .containerDefault();
    }
  }
  .footer {
    .footer-body {
      .containerDefault();
    }
  }
}
@media (max-width: @mobileWidth) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px 0;
  }
  body {
    // font-weight: 500;
    font-size: 12px;
  }
  .content {
    padding: 12px 10px;
    min-width: 100%;
    width: 100%;
  }
  .overview-radio {
    flex-direction: column;
    padding: 12px 0;
    :first-child {
      margin-bottom: 5px;
    }
  }
  .overview-charts-charts-box {
    padding: 0;
  }
  .lending-radio {
    padding: 5px 0;
  }
  .header {
    padding: 12px 10px;
    min-width: 100%;
    width: 100%;
  }
  .footer {
    padding: 12px 10px;
    min-width: 100%;
    width: 100%;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
}

@primary-color: #4E81F8;