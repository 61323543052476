@import '../../common/variables.less';
@toolHeight: 35px;
.income-tool {
  margin-top: 10px;
  margin-bottom: 10px;
  .ant-col {
    margin-left: 20px;
  }
  :first-child {
    margin-left: 0;
  }
}
.income-tip-icon {
  margin-left: 0;
  margin-right: 15px;
}
.income-tip-box {
  justify-content: space-between;
  align-items: center;
  span {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
}
.income-input {
  width: 120px;
  height: @toolHeight;
}
.income-dropdown {
  height: @toolHeight;
}
.income-month {
  font-size: 14px;
}
.income-tool .ant-input-number-handler-wrap {
  opacity: 1;
}

@media (max-width: @mobileWidth) {
  .income-tool {
    flex-direction: column;
    .ant-col {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}

@primary-color: #4E81F8;