@import '../../common/variables.less';
.overview {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.overview-left-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.home-search {
  width: 300px;
}
.home-radio-search {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: @mobileWidth) {
  .home-radio-search {
    flex-wrap: wrap;
  }
  .home-search {
    margin-top: 10px;
    margin-left: auto;
    width: 100%;
    margin-bottom: 10px;
  }
}

@primary-color: #4E81F8;