@import '../../common/variables.less';
@ChartHeight: 400px;
@mobileChartHeight: 350px;
.eharts-loading {
  line-height: @ChartHeight;
  left: 50%;
  position: absolute;
  .antd-loading-lg();
}
.eharts-box {
  position: relative;
  height: @ChartHeight;
}
@media (max-width: @mobileWidth) {
  .eharts-box {
    height: @mobileChartHeight;
  }
  .eharts-loading {
    left: 48%;
    height: @mobileChartHeight;
  }
}

@primary-color: #4E81F8;