@import '../../../../common/variables.less';
.border-bottom{
  border-bottom: 1px solid @borderColor;
}
.de-radar-info-stats {
  flex: 1;
  position: relative;
  max-width: 350px;
  min-width: 300px;
  box-shadow: 1px 1px 1px @borderColor;
  font-weight: 500;
  color: @textColor;
  border: 1px solid @borderColor;
  border-radius: 5px;
  margin: 20px 20px 0 10px;
  h4 {
    padding: 10px 15px;
    padding-top: 15px;
  }
}
.stats-item-box {
  padding: 10px 0;
}
.item2-row {
  padding: 0;
}
.stats-item-title {
  color: @grayText;
}
.stats-item2 {
  padding: 10px 0;
  .border-bottom();
}
.border-bottom-div {
  .border-bottom();
  div {
    .border-bottom();
  }
}
.stats-item2-box {
  padding-left: 10px;
}
.de-radar-info-stats-box {
  border-top: 1px solid @borderColor;
  padding: 0 15px;
  :last-child {
    border-bottom: 0;
  }
}
@media (max-width: @mobileWidth) {
  .de-radar-info-stats {
    width: 100%;
    margin: 15px 0;
    max-width: 1000px;
  }
}
@primary-color: #4E81F8;