@import '../../common/variables.less';
.de-radar-project-content {
  a {
    color: @primaryColor;
  }
  h1 {
    color: @textColor;
  }
}

@primary-color: #4E81F8;