@import '../../common/variables.less';
.overview-card-box {
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  margin: 5px;
  border: 1px solid @borderColor;
  min-width: 150px;
  h2 {
    font-weight: bold;
    font-size: inherit;
  }
}
.overview-card-box-span {
  font-size: 20px;
}
@media (max-width: @mobileWidth) {
  .overview-card-box {
    min-width: 40%;
  }
}

@primary-color: #4E81F8;