@import '../../../../common/variables.less';
.text-center {
  text-align: center;
}
.de-radar-emoji {
  padding-left: 10px !important;
  width: 30px;
}
.de-radar-placeholder {
  padding-left: 13px !important;
  width: 50px;
}
.de-radar-cup {
  font-size: 12px;
}
.de-radar-date {
  width: 60px;
}
.de-radar-home-table {
  .ant-table-thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
.de-radar-name {
  width: 140px;
}
@media (max-width: @mobileWidth) {
  .de-radar-date {
    width: 50px;
  }
  .de-radar-name {
    text-align: left;
    padding: 0;
    width: 100px;
  }
}

@primary-color: #4E81F8;