@import '../../common/variables.less';
@import '~antd/lib/style/themes/default.less';
.header-body {
  display: flex;
  font-weight: 500;
  flex-direction: row;
  a {
    text-decoration: none;
  }
}
.header-mobile-nav {
  a {
    text-decoration: none;
  }
}
.de-radar-nav {
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.header-logo {
  height: @headerHeight;
  margin-right: 10px;
  cursor: pointer;
  width: 100px;
}
#header-nav.ant-menu-horizontal {
  height: @headerHeight;
  line-height: @headerHeight;
  border: none;
  .ant-menu-item-selected {
    border: none;
  }
  .ant-menu-submenu {
    &:hover {
      border: none;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    min-width: 40px;
    height: @headerHeight;
    margin: 0 16px;
    line-height: @headerLineHeight;
    border: none;
  }
}
@media (max-width: @mobileWidth) {
  .header-logo {
    height: @headerHeight - 24px;
  }
  .header-nav-affix {
    //The z-index that needs to be set is larger than the z-index of the echarts tooltip
    z-index: 99999999;
  }
  .header-nav-icon {
    font-size: 20px;
  }
  .de-radar-nav {
    flex-direction: row-reverse;
  }
  .top-drawer {
    margin-top: @headerHeight;
  }
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title:hover {
    color: @fontColor;
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before {
    background: @fontColor;
  }
  .header-nav-border {
    border-bottom: 1px solid @borderColor;
  }
}

@primary-color: #4E81F8;