@import '../../common/variables.less';
.radio-text-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.radio-text-item {
  cursor: pointer;
  color: @fontColor;
  font-weight: 500;
}
.radio-text-selected {
  color: inherit;
  text-decoration: underline;
}
.margin-item {
  margin: 0 5px;
}
.last {
  margin-right: 0px;
}

@primary-color: #4E81F8;