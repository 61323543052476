@import '../../common/variables.less';
.de-radar-red-color {
  color: @red;
}
.de-radar-green-color {
  color: @green;
}
.de-radar-token-day {
  color: @textColor;
}
token1Day .rate-tip {
  cursor: pointer;
}

@primary-color: #4E81F8;