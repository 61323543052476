@import '../../../../common/variables.less';
.income-table-apr {
  font-size: 22px;
  color: @primaryColor;
}
.income-table-apr-month {
  font-weight: 500;
  font-size: 14px;
}
.income-table-apr-font-grey {
  color: @grayText;
}
.income-table-apr-next {
  font-size: 12px;
  font-weight: bold;
  background: @primaryColor;
  color: @lightTextColor;
  padding: 8px 10px;
  border-radius: 6px;
  letter-spacing: 1px;
  text-decoration: none;
}
.income-table-apr-next:hover {
  color: @lightTextColor;
}

@media (max-width: @mobileWidth) {
  .income-table-apr {
    font-size: 16px;
  }
  .income-table-apr-next {
    padding: 5px 10px;
  }
  .income-table-apr-month {
    font-size: 12px;
  }
  .income-table-left {
    width: 80px;
  }
}

@primary-color: #4E81F8;