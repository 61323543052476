@import '../../common/variables.less';
.de-radar-info {
  h1,
  h2,
  h3 {
    color: @textColor;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  .de-radar-info-title-token {
    font-size: 16px;
    color: @grayText;
  }
  a {
    color: @primaryColor;
    font-size: inherit;
  }
}
.de-radar-info .de-radar-info-name {
  border-bottom: 1px solid @borderColor;
  padding-bottom: 10px;
}
.de-radar-info-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.de-radar-info-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.de-radar-info-status {
  display: flex;
  margin-right: 30px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.de-radar-info-links {
  text-align: right;
}
.info-tabs {
  padding-top: 15px;
}
.de-radar-info-status-box {
  margin-top: 5px;
}
.de-radar-info-faq-container {
  color: @textColor;
  p {
    color: inherit;
  }
  h2 {
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
  }
}
.overview-info-charts {
  align-items: flex-start;
  flex-direction: row;
}
.overview-info-charts-box {
  flex: 2;
}
.de-radar-info-info-pc {
  display: block;
}
.de-radar-info-info-mobile {
  display: none;
}
@media (max-width: 1000px) {
  .overview-info-overview-radio {
    flex-direction: column;
    padding: 12px 0;
    :first-child {
      margin-bottom: 5px;
    }
  }
}
@media (max-width: @mobileWidth) {
  .de-radar-info-status-box{
    padding-top: 10px;
  }
  .de-radar-info-links {
    text-align: left;
  }
  .de-radar-info-status .de-radar-info-info{
    margin-top: 10px;
  }
  .overview-info-charts {
    flex-direction: column;
  }
  .overview-info-charts-box {
    width: 100%;
    flex: 1;
  }
  .de-radar-info-info-pc {
    display: none;
  }
  .de-radar-info-info-mobile {
    display: block;
  }
}

@primary-color: #4E81F8;