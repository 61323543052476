@import '../../../../common/variables.less';
.faq-container {
  margin-bottom: 30px;
  margin-top:60px;
  font-family: 'Karla', sans-serif;
  max-width: @bodyWidth;
  a {
    color: @primaryColor;
  }
}

@primary-color: #4E81F8;