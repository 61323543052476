@import '../../../common/variables.less';
.card-item {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
}
.card-item .card-item-title {
  font-weight: 500;
}
.card-item-details{
  margin-left: 10px;
}

@primary-color: #4E81F8;