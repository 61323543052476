@import '../../common/variables.less';
.footer-body {
  flex: 1;
  height: '100%';
  display: flex;
  align-items: center;
  flex-direction: row;
}
.footer-logo {
  cursor: pointer;
  width: 100px;
}
.footer-tool {
  display: flex;
  margin: auto 0;
  flex-direction: row;
  font-weight: 500;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;
  a {
    cursor: pointer;
    margin: 0 30px;
    text-decoration: none;
  }
}
@media (max-width: @mobileWidth) {
  .footer-body {
    align-items: flex-start;
    flex-direction: column;
  }
  .footer-tool {
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
    a {
      margin: 0;
    }
  }
}

@primary-color: #4E81F8;