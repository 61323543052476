@import '../../common/variables.less';
.radio-box {
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.radio-item {
  border: 0;
  background: @boxColor;
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: bold;
  color: @grayText;
}
.radio-item:last-child {
  margin-right: 0px;
}
.radio-item:hover {
  background: @secondaryBGColor;
  color: @textColor;
}

.radio-selected {
  background: @primaryColor;
  border: 1px solid @primaryColor;
  color: @lightTextColor;
}
.radio-selected:hover {
  .radio-selected();
}

@primary-color: #4E81F8;