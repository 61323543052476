@import '../../common/variables.less';
.de-radar-loading-box {
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
  opacity: 0.4;
}
.de-radar-loading-spin {
  margin-top: 50%;
  .antd-loading-lg();
}

@primary-color: #4E81F8;